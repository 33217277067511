import { fetchError, fetchLoading, fetchSuccess, stateHasError } from "@openstax/ts-utils/fetch";
import * as UI from '@openstax/ui-components';
import { brightspaceNameTaken } from '@project/lambdas/build/src/functions/serviceApi/versions/v0/errorMessages';
import { dynamicRegistrationPath } from '@project/lambdas/build/src/services/LtiJs/paths.config';
import React from 'react';
import { BodyWrapper, CustomerSupportLink, Footer, ScreenWrapper, StateContext } from '.';
import { closeLtiIframe } from './helpers';
import { ScreenState, initialState, reducer } from './reducer';
import { createRoute, makeScreen } from '../../../core/services';
import { useQuery } from '../../../routing/useQuery';
import { AdminInfoFields, FormTag, SubmitAdminInfoButton, useAdminInfoIsValid } from "./AdminInfoForm";
import { useApiClient } from "../../../api";
import * as Sentry from '@sentry/react';

const ltiSuccessMessageDelay = 10000;

const DynamicCreateScreen = () => {
  const apiClient = useApiClient();
  const { state, dispatch } = React.useContext(StateContext);
  const { adminName, adminEmail, organizationId } = state.contactPayload;
  const isValid = useAdminInfoIsValid();

  const onSubmit = () => {
    if (!organizationId || !isValid || !state.dynamicPlatformPayload) {
      return;
    }

    dispatch({ type: 'setApiState', payload: fetchLoading() });

    apiClient.apiV0CreateDynamicPlatform({
      payload: {
        platform: state.dynamicPlatformPayload,
        contact: {
          adminEmail,
          adminName,
          organizationId,
      }}
    })
    .then(response => response.acceptStatus(201, 409))
    .then((response) => {
      if (response.status === 409) {
        response.load().then(body => dispatch({
          type: 'setApiState',
          payload: fetchError(
            body.cause === brightspaceNameTaken ?
              'An integration named "OpenStax" already exists in Brightspace. ' +
                'Please rename your integration and resubmit the LTI registration form to proceed.' :
              'Platform already registered.'
          ),
        }));
      } else {
        dispatch({
          type: 'setApiState',
          payload: fetchSuccess(null)
        });

        dispatch({
          type: 'setActiveScreen',
          payload: 'success'
        });

        setTimeout(closeLtiIframe, ltiSuccessMessageDelay);
      }
    })
    .catch((e) => {
      Sentry.captureException(e);
      dispatch({
        type: 'setApiState',
        payload: fetchError('An error occurred, please try again.')
      });
    });
  };

  return <ScreenWrapper flex>
    <FormTag onSubmit={onSubmit}>
      <BodyWrapper>
        <AdminInfoFields />
      </BodyWrapper>
      <Footer>
        <SubmitAdminInfoButton />
      </Footer>
    </FormTag>
  </ScreenWrapper>;
};

const DynamicSuccessScreen = () => (
  <ScreenWrapper>
    <UI.NavBar logo />
    <BodyWrapper>
      <p>
        Thank you for registering OpenStax Assignable.
        Please ensure the integration is active and any necessary
        deployments and placements have been created in your LMS.
      </p>

      <p>Have other questions? Visit our <CustomerSupportLink>Support Center</CustomerSupportLink>.</p>
    </BodyWrapper>
  </ScreenWrapper>);

export const DynamicRegisterScreen = () => {
  const query = useQuery();
  const initState: ScreenState = { ...initialState, activeScreen: 'create' };
  if (typeof query.openid_configuration === 'string') {
    initState.dynamicPlatformPayload = {
      openidConfiguration: query.openid_configuration,
      registrationToken: typeof query.registration_token === 'string' ? query.registration_token : undefined,
    };
  } else {
    initState.apiState = fetchError('MISSING_OPENID_CONFIGURATION');
  }
  const [state, dispatch] = React.useReducer(reducer, initState);

  const ActiveScreen = state.activeScreen === 'success' ? DynamicSuccessScreen : DynamicCreateScreen;

  return <StateContext.Provider value={{ state, dispatch }}>
    {stateHasError(state.apiState)
      ?  <UI.ErrorModal
        show
        heading=''
        onModalClose={closeLtiIframe}
      >
        {<>{state.apiState.error} If the problem persists,
        visit our <CustomerSupportLink page="contactsupport">support center</CustomerSupportLink>.</>}
      </UI.ErrorModal>
      : <ActiveScreen />
    }
  </StateContext.Provider>;
};

export const dynamicRegisterScreen = createRoute({
  name: 'DynamicRegistration',
  path: dynamicRegistrationPath,
  handler: makeScreen(DynamicRegisterScreen, true),
});
