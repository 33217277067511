import React from 'react';
import { createRoute, makeScreen } from "../../core/services";
import { createGlobalStyle } from 'styled-components';
import { useApiClient } from "../../api";
import {
  FetchState,
  fetchLoading,
  fetchSuccess,
  stateHasData,
  fetchIdle,
} from "@openstax/ts-utils/fetch";
import { assertString } from "@openstax/ts-utils/assertions";
import * as UI from '@openstax/ui-components';
import {launchContentPath} from '@project/lambdas/build/src/services/LtiJs/paths.config';
import {
  isActivityProgressMessage,
  isSyncScoresMessage,
  isTriggerConsentModalMessage,
  makeSyncScoresCompleteMessage
} from '@openstax/lti/lti-gateway/post-messages';
import { LtiLinkedAccountProvider, AccessRole } from "../../ltiLinkedAccount/components/LtiLinkedAccountProvider";
import { useEmbeddedAuthProvider } from "../../hooks/useEmbeddedAuthProvider";
import { useLtiLinkedUserData } from "../../ltiLinkedAccount/context";
import { ConsentWrapper } from '../../components/ConsentWrapper';

const GlobalStyle = createGlobalStyle`
  body {
    overflow: hidden;
  }
`;

export const useConsentModalTrigger = (url: string | undefined) => {
  React.useEffect(() => {
    const handleMessage = (e: MessageEvent<any>) => {
      if (url && isTriggerConsentModalMessage(e.data) && e.origin === new URL(url).origin) {
        const consentRevisitButton = document.createElement('button');
        consentRevisitButton.className = 'cky-banner-element';
        consentRevisitButton.style.display = 'none';
        document.body.appendChild(consentRevisitButton);

        consentRevisitButton.click();

        document.body.removeChild(consentRevisitButton);
      }
    };
  
    window.addEventListener('message', handleMessage, false);
    return () => window.removeEventListener('message', handleMessage);
  }, [url]);
};

export const useProgressTrigger = (activityUrl: string | undefined) => {
  const apiClient = useApiClient();
  const setAppError = UI.useSetAppError();
  const [state, setState] = React.useState<FetchState<null, string>>(fetchIdle());

  const handleCallback = React.useCallback((e: MessageEvent<any>) => {
    if (activityUrl && isActivityProgressMessage(e.data) && e.origin === new URL(activityUrl).origin) {
      setState(previous => fetchLoading(previous));

      const params = e.data.payload;
      const query = { syncPartialScores: params.syncPartialScores === true ? 'true' : 'false' };
      apiClient.apiV0UpdateProgress({ params, query })
        .then(response => response.acceptStatus(204))
        .then(() => setState(fetchSuccess(null)))
        .catch(setAppError)
      ;
    }
  }, [apiClient, activityUrl, setAppError]);

  React.useEffect(() => {
    window.addEventListener('message', handleCallback, false);
    return () => window.removeEventListener('message', handleCallback);
  }, [handleCallback]);

  return state;
};

export const useSyncScoresTrigger = (editUrl?: string) => {
  const apiClient = useApiClient();

  const handleCallback = React.useCallback((e: MessageEvent<any>) => {
    if (editUrl && isSyncScoresMessage(e.data) && e.origin === new URL(editUrl).origin) {
      const { id, syncIncompleteScores } = e.data.payload;
      apiClient.apiV0SyncAssignmentScores(
        { params: { id }, query: { syncIncompleteScores: syncIncompleteScores.toString() } }
      )
        .then(response => response.acceptStatus(200))
        .then(response => response.load(), () => {
          return {
            successIds: [],
            failures: [ { message: 'unknown', name: 'unknown', userId: 'unknown' } ],
            skippedIds: syncIncompleteScores ? [] : [ 'unknown' ]
          };
        })
        .then(body => e.source?.postMessage(makeSyncScoresCompleteMessage(body), {targetOrigin: e.origin}))
      ;
    }
  }, [apiClient, editUrl]);

  React.useEffect(() => {
    if (!editUrl) {
      return () => {};
    }

    window.addEventListener('message', handleCallback, false);
    return () => window.removeEventListener('message', handleCallback);
  }, [editUrl, handleCallback]);
};

export const useActivityUrl = () => {
  const apiClient = useApiClient();
  const setAppError = UI.useSetAppError();
  const [state, setState] = React.useState<FetchState<{url: string}, string>>(fetchLoading());

  React.useEffect(() => {
    apiClient.apiV0DeepLinkUrl({})
      .then(response => response.acceptStatus(200).load())
      .then(response => setState(fetchSuccess(response)))
      .catch(setAppError)
    ;
  }, [apiClient, setAppError]);

  return state;
};

export const useEditUrl = () => {
  const apiClient = useApiClient();
  const setAppError = UI.useSetAppError();
  const [state, setState] = React.useState<FetchState<{url?: string}, string>>(fetchLoading());

  React.useEffect(() => {
    apiClient.apiV0DeepLinkEditUrl({})
      .then(response => response.acceptStatus(200).load())
      .then(response => setState(fetchSuccess(response)))
      .catch(setAppError)
    ;
  }, [apiClient, setAppError]);

  return state;
};

const Embed = ({url}: {url: string}) => {
  const userData = useLtiLinkedUserData();
  const authProvider = useEmbeddedAuthProvider(userData);

  React.useEffect(() => {
    document.body.classList.add('os-embed');
    return () => document.body.classList.remove('os-embed');
  }, []);

  return <ConsentWrapper><iframe
    title="launch content"
    style={{width: '100%', height: '100%', border: 'none'}}
    src={authProvider.getAuthorizedEmbedUrl(url)}
  ></iframe></ConsentWrapper>;
};

export const Launch = () => {
  const state = useActivityUrl();
  const activityUrl = stateHasData(state) ? state.data.url : undefined;

  useProgressTrigger(activityUrl);
  useConsentModalTrigger(activityUrl);

  return stateHasData(state)
      ? <Embed url={state.data.url} />
      : <UI.Loader />;
};

export const Edit = ({url}: {url: string}) => {
  useSyncScoresTrigger(url);
  useConsentModalTrigger(url);

  return <Embed url={url} />;
};

export const LaunchSwitch = () => {
  const state = useEditUrl();
  const canEdit = useLtiLinkedUserData().role === AccessRole.INSTRUCTOR && (!stateHasData(state) || state.data.url);
  const editUrl = canEdit && stateHasData(state) ?
    assertString(state.data.url, new Error('edit url must be a string')) : undefined;

  // render student view if user does not have instructor role or editUrl is missing
  return canEdit ?
           editUrl ? <Edit url={editUrl} /> : <UI.Loader /> :
           <Launch />;
};

export const LaunchWithAccount = () => {
  return <LtiLinkedAccountProvider>
    <GlobalStyle />
    <LaunchSwitch />
  </LtiLinkedAccountProvider>;
};

export const launchScreen = createRoute({name: 'Launch', path: launchContentPath,
  handler: makeScreen(LaunchWithAccount)
});
